export const authRoles = {
  sa: ["SA"], // Only Super Admin has access
  admin: ["SA", "ADMIN", "PILOT", "PATIENT"], // Only SA & Admin has access
  editor: ["SA", "ADMIN", "EDITOR"], // Only SA & Admin & Editor has access
  guest: ["SA", "ADMIN", "EDITOR", "GUEST"] // Everyone has access
};

export const defaultRoutes = {
  "SA": "/dashboard/default",
  "PILOT": "/dashboard/default",
  "PATIENT": "/dashboard/default",
  "ADMIN": "/dashboard/default",
  "EDITOR": "/dashboard/default",
  "GUEST": "/dashboard/default",
}