import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box, Card, Checkbox, Grid, styled, TextField, useTheme, Collapse, Hidden } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
// GLOBAL CUSTOM COMPONENTS
import { FlexAlignCenter } from "app/components/FlexBox";
import { Paragraph } from "app/components/Typography";
// GLOBAL CUSTOM HOOKS
import useAuth from "app/hooks/useAuth";
import { AlignVerticalCenter, CenterFocusStrong } from "@mui/icons-material";
import './loginbg.css';
import './animebg.css';
import React from "react";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useConfig } from 'app/contexts/ConfigContext';
import zIndex from "@mui/material/styles/zIndex";
import axios from "axios";

// styled components
const FlexBox = styled(Box)({
    display: "flex",
    alignItems: "center"
});

const ContentBox = styled("div")({
    height: "100%",
    padding: "32px",
    position: "relative",
    background: "rgba(0, 0, 0, 0.01)"
});

const JWTRoot = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    position: "relative",
    overflow: "hidden",
    "& .card": {
        display: "flex",
        alignItems: "center",
        maxWidth: 800,
        minHeight: 400,
        borderRadius: 12,
        margin: "1rem",
        zIndex: 1,
    }
});

// initial login credentials
const initialValues = {
    username: "",
    password: "",
    remember: true
};

// const initialValues = {
//     username: "iam@shawon.info",
//     password: "shawon123",
//     remember: true
// };
// form field validation schema
const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, "Password must be 6 character length")
        .required("Password is required!"),
    username: Yup.string().email("Invalid Email address").required("Email is required!")
});

export default function MissionLogin() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { apiUrl } = useConfig();
    const userData = useAuth();
    const { login } = useAuth();
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleAlartClose = () => {
        setOpen(false);
    };

    const handleFormSubmit = async (values) => {
        try {
            await login(values.username, values.password);
            navigate("/");
        } catch (e) {
            setError("Invalid email / password..!");
            setOpen(true);
        }
    };

    const [isAnimated, setAnimation] = useState(true);

    const fetchAdminData = async () => {
        try {
            const response = await axios.get(apiUrl + `settings/getAll`);

            const data = response.data[0];
            setAnimation(data.animation_active);
            // console.log("animation :", data.animation_active);
            // console.log("fetched data: ", data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchAdminData();
    }, []);

    return (
        <JWTRoot>
            {isAnimated && (
                <>
                    <div class="mainbody">
                        <div class="anime-background-wrap">
                            <div class="x1">
                                <div class="cloud"></div>
                            </div>

                            <div class="x2">
                                <div class="cloud"></div>
                            </div>

                            <div class="x3">
                                <div class="cloud"></div>
                            </div>

                            <div class="x4">
                                <div class="cloud"></div>
                            </div>

                            <div class="x5">
                                <div class="cloud"></div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {!isAnimated && (
                <>
                    <div className="background"></div>
                </>
            )}


            <Card className="card">
                <Grid container sx={{ minHeight: '400px' }}>
                    <Grid item sm={6} xs={12} style={{ backgroundColor: 'dodgerblue' }}>
                        <FlexAlignCenter>
                            <h2>Welcome Back!</h2>
                        </FlexAlignCenter>
                        <div style={{ alignItems: 'center', textAlign: 'center', width: '100%', fontSize: '24px', fontWeight: 'bold' }}>Mercy Flights Orcas</div>
                        <FlexAlignCenter p={2}>
                            <img src={`${apiUrl}images/login-logo.png`} width="70%" alt="" />
                        </FlexAlignCenter >
                    </Grid >

                    <Grid item sm={6} xs={12}>
                        <Collapse in={open}>
                            <Alert severity="warning" action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        handleAlartClose();
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                                sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        </Collapse>
                        <ContentBox>
                            <h2>Login To Account</h2>
                            <p>Please enter your email and password to continue</p>
                            <br />
                            <Formik
                                onSubmit={handleFormSubmit}
                                initialValues={initialValues}
                                validationSchema={validationSchema}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="email"
                                            name="username"
                                            label="Email"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.username}
                                            onChange={handleChange}
                                            helperText={touched.username && errors.username}
                                            error={Boolean(errors.username && touched.username)}
                                            sx={{ mb: 3 }}
                                        />

                                        <TextField
                                            fullWidth
                                            size="small"
                                            name="password"
                                            type="password"
                                            label="Password"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.password}
                                            onChange={handleChange}
                                            helperText={touched.password && errors.password}
                                            error={Boolean(errors.password && touched.password)}
                                            sx={{ mb: 1.5 }}
                                        />

                                        <FlexBox justifyContent="space-between">
                                            <FlexBox gap={1}>
                                                <Checkbox
                                                    size="small"
                                                    name="remember"
                                                    onChange={handleChange}
                                                    checked={values.remember}
                                                    sx={{ padding: 0 }}
                                                />

                                                <Paragraph>Remember Me</Paragraph>
                                            </FlexBox>

                                            <NavLink
                                                to="/session/forgot-password"
                                                style={{ color: theme.palette.primary.main }}>
                                                Forgot password?
                                            </NavLink>
                                        </FlexBox>

                                        <LoadingButton
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            loading={isSubmitting}
                                            sx={{ my: 2 }}>
                                            Login
                                        </LoadingButton>

                                        {/* <Paragraph>
                                            Don't have an account?
                                            <NavLink
                                                to="/session/signup"
                                                style={{ color: theme.palette.primary.main, marginLeft: 5 }}>
                                                Register
                                            </NavLink>
                                        </Paragraph> */}
                                    </form>
                                )}
                            </Formik>
                        </ContentBox>
                    </Grid>
                </Grid >
            </Card >
        </JWTRoot >
    );
}
