import { Navigate, useLocation } from "react-router-dom";
import useAuth from "app/hooks/useAuth";
import { defaultRoutes } from "./authRoles";

export default function Redirect() {
  let location = useLocation();
  const { user } = useAuth();
  const defaultRute = user?.role ? defaultRoutes[user.role] : "/dashboard/default";
  // Get redirect location or provide fallback
  const from = location.state?.from || defaultRute;

  // in auth callback logic, once authenticated navigate (redirect) back
  // to the route originally being accessed.
  return <Navigate to={from} />;
}
