import { lazy } from "react";
import Loadable from "app/components/Loadable";
import MissionConfirmation from "./Confirm";

const confirm = Loadable(lazy(() => import("./Confirm")));

const noheadRoutes = [
    { path: "/mission/confirm/:missionId/:pilotId", element: <MissionConfirmation /> },
];

export default noheadRoutes;