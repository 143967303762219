import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useConfig } from 'app/contexts/ConfigContext';

const MissionConfirmation = () => {
    const { missionId, pilotId } = useParams();
    const [message, setMessage] = useState('');
    const { apiUrl } = useConfig();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(apiUrl + 'mission/confirm', {
                missionId,
                pilotId,
            });
            if (response.data === "success") {
                setMessage('Success! The mission has been confirmed.');
            } else {
                setMessage(response.data);
            }
        } catch (error) {
            setMessage('Failed to confirm the mission.');
        }
    };

    return (
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Typography variant="h4" gutterBottom>Are you taking the mission?</Typography>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
                <input type="hidden" name="missionId" value={missionId} />
                <input type="hidden" name="pilotId" value={pilotId} />
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                    Yes
                </Button>
            </form>
            {message && <Typography variant="h6" color="secondary" style={{ marginTop: '20px' }}>{message}</Typography>}
        </Container>
    );
};

export default MissionConfirmation;