import useAuth from "app/hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import { flatten } from 'lodash'
import routes from "../routes";
import AccessDenied from '../views/sessions/AccessDenied'

const userHasPermission = (pathname, user, routes) => {
  if (!user) {
    return false;
  }
  // let flatRoutes = [routes];
  const flatRoutes = routes.reduce((acc, curr) => {
    acc.push(curr);
    if (curr.children) {
      acc.push(...curr.children);
    }
    return acc;
  }, []);


  const matched = flatRoutes.find((r) => r.path === pathname);
  // console.log(matched)
  const authenticated =
    matched && matched.auth && matched.auth.length ? matched.auth.includes(user.role) : true;
  return authenticated;
};

export default function AuthGuard({ children }) {
  const { pathname } = useLocation();
  const { isAuthenticated, user } = useAuth();

  const hasPermission = userHasPermission(pathname, user, routes);
  if (isAuthenticated && !hasPermission) {
    return <AccessDenied />;
  }
  let authenticated = isAuthenticated && hasPermission;

  if (authenticated) return <>{children}</>;

  return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
}
