import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useConfig } from 'app/contexts/ConfigContext';

// Create a context
const ColorContext = createContext();

export const useColors = () => useContext(ColorContext);

export const ColorProvider = ({ children }) => {
    const [colors, setColors] = useState({
        success: '#28a745',  // default fallback colors
        warning: '#ffc107',
        danger: '#dc3545',
        sidebar: '#dc3545',
    });
    const { apiUrl } = useConfig();

    useEffect(() => {
        const fetchColors = async () => {
            try {
                const response = await axios.get(apiUrl + 'settings/getAllByEmail/test@ermail.com');
                const colorData = {
                    success: response.data.success_color,
                    danger: response.data.danger_color,
                    warning: response.data.warning_color,
                    sidebar: response.data.sidebar_color,
                };
                setColors(colorData);
            } catch (error) {
                console.error('Failed to fetch colors:', error);
            }
        };

        fetchColors();
    }, []);

    return (
        <ColorContext.Provider value={colors}>
            {children}
        </ColorContext.Provider>
    );
};
