import Account from "./views/account";
import Redirect from "./auth/Redirect";
import AuthGuard from "./auth/AuthGuard";
import ListRoute from "./views/list/list-route";
import crudRoute from "./views/CRUD/crud-routes";
import NotFound from "./views/sessions/NotFound";
import pagesRoutes from "./views/pages/pages-routes";
import chartsRoute from "./views/charts/charts-route";
import sessionRoutes from "./views/sessions/session-routes";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import calendarRoutes from "./views/calendar/calendar-routes";
import dashboardRoutes from "./views/dashboard/dashboard-routes";
import materialRoutes from "./views/material-kit/MaterialRoutes";
import dragAndDropRoute from "./views/Drag&Drop/drag-drop-routes";
import pageLayoutRoutes from "./views/page-layouts/page-layout-routes";
import noheadRoutes from "./views/nohead/NoheadRoutes";

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...calendarRoutes,
      ...chartsRoute,
      ...crudRoute,
      ...dragAndDropRoute,
      ...ListRoute,
      ...materialRoutes,
      ...pageLayoutRoutes,
      ...pagesRoutes,
      { path: "/page-layouts/account", element: <Account /> }
    ]
  },
  ...noheadRoutes,
  ...sessionRoutes,
  { path: "/", element: <Redirect /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
