import Mock from "../mock";

const baseToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiYWdlIjoyNSwicm9sZSI6IkFETUlOIiwibmFtZSI6Ikphc29uIEFsZXhhbmRlciIsImVtYWlsIjoiamFzb25AdWktbGliLmNvbSIsInVzZXJuYW1lIjoiamFzb25fYWxleGFuZGVyIiwiYXZhdGFyIjoiL2Fzc2V0cy9pbWFnZXMvZmFjZS02LmpwZyJ9.BILDCjrPpk2ohysHiWScnVvEoxI6UpH1vBmS59KjiNo";

const userList = [
  {
    id: 1,
    age: 25,
    role: "SA",
    name: "Jason Alexander",
    email: "jason@ui-lib.com",
    username: "jason_alexander",
    avatar: "/assets/images/face-6.jpg"
  }
];

let tokenUserMap = {};

Mock.onPost("/api/auth/login").reply(async (config) => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const { email } = JSON.parse(config.data);
    const user = userList.find((u) => u.email === email);
    console.log(user);
    if (!user) {
      return [400, { message: "Invalid email or password" }];
    }

    const userToken = `${baseToken}.${user.id}`;
    tokenUserMap[userToken] = user;

    console.log('User token data:', tokenUserMap);
    return [
      200,
      {
        accessToken: userToken,
        user: {
          id: user.id,
          name: user.name,
          role: user.role,
          email: user.email,
          avatar: user.avatar
        }
      }
    ];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

Mock.onPost("/api/auth/register").reply((config) => {
  try {
    const { email, username } = JSON.parse(config.data);
    const user = userList.find((u) => u.email === email);

    if (user) {
      return [400, { message: "User already exists!" }];
    }

    const newUser = {
      id: 2,
      age: 25,
      email: email,
      role: "GUEST",
      name: "Guest User",
      username: username,
      avatar: "/assets/images/face-6.jpg"
    };

    userList.push(newUser);

    const userToken = `${baseToken}.${newUser.id}`;
    tokenUserMap[userToken] = newUser;

    return [
      200,
      {
        accessToken: userToken,
        user: {
          id: newUser.id,
          role: newUser.role,
          name: newUser.name,
          email: newUser.email,
          avatar: newUser.avatar,
          username: newUser.username
        }
      }
    ];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

Mock.onGet("/api/auth/profile").reply((config) => {
  try {
    const { Authorization } = config.headers;
    console.log('Authorization Header:', Authorization);

    if (!Authorization) {
      return [401, { message: "Invalid Authorization token" }];
    }

    const userToken = Authorization.split(' ')[1];
    console.log('User token to retrieve data:', tokenUserMap);
    const user = tokenUserMap[userToken];
    console.log('User Retrieved:', user);

    if (!user) {
      return [401, { message: "Invalid Authorization token" }];
    }

    return [200, { user }];
  } catch (err) {
    console.error('Profile retrieval error:', err);
    return [500, { message: "Internal server error" }];
  }
});
