import { useNavigate } from "react-router-dom";
import { Button, styled } from "@mui/material";
import { useConfig } from 'app/contexts/ConfigContext';
// STYLED COMPONENTS
const JustifyBox = styled("div")({
  maxWidth: 320,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center"
});

const IMG = styled("img")({
  width: "100%",
  marginBottom: "32px"
});

const NotFoundRoot = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh !important"
});

export default function NotFound() {
  const navigate = useNavigate();
  const { apiUrl } = useConfig();

  return (
    <NotFoundRoot>
      <JustifyBox>
        <IMG src="/assets/images/illustrations/404.svg" alt="" />

        <Button
          color="primary"
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </JustifyBox>
    </NotFoundRoot>
  );
}
