import { createContext, useEffect, useReducer } from "react";
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import { useConfig } from 'app/contexts/ConfigContext';
// GLOBAL CUSTOM COMPONENTS
import Loading from "app/components/MatxLoading";
// import { appConfig } from "config";
import { useNavigate } from "react-router-dom";

const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
};

const isValidToken = (accessToken) => {
  if (!accessToken) return false;
  try {
    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    //console.log('Decoded Token:', decodedToken);
    return decodedToken.exp > currentTime;
  } catch {
    return false;
  }
};

const setSession = (accessToken) => {
  //console.log("From session settings", accessToken);
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, user, isAuthenticated, isInitialized: true };
    }
    case "LOGIN": {
      const { user } = action.payload;
      return { ...state, user, isAuthenticated: true };
    }
    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }
    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: () => { },
  login: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { apiUrl } = useConfig();

  const login = async (username, password) => {
    const { data } = await axios.post(apiUrl + 'auth/authenticate', { username, password });
    const { accessToken } = data;
    const decoded = jwtDecode(accessToken);
    //console.log(decoded);
    var name_to_show = decoded.preferred_username.split("@")[0];
    setSession(accessToken);
    const response = await axios.get(apiUrl + 'auth/profile', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const userinfo = response.data;
    //console.log("From Auth: ", userinfo);
    const user = {
      id: userinfo.id,
      age: 25,
      email: username,
      role: decoded.rol[0],
      name: userinfo.name,
      username: username,
      avatar: "/assets/images/face-6.jpg"
    };

    dispatch({ type: "LOGIN", payload: { user } });
  };

  const register = async (username, password) => {
    const { data } = await axios.post(apiUrl + 'auth/signup', { username, password });
    const { accessToken, user } = data; // Adjusted to match the API response

    setSession(accessToken);
    dispatch({ type: "REGISTER", payload: { user } });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        //console.log('Access Token on Init:', accessToken);

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const response = await axios.get(apiUrl + 'auth/profile', {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          const userinfo = response.data;
          //console.log(userinfo);
          var name_to_show = userinfo.username.split("@")[0];
          const user = {
            id: userinfo.id,
            age: 25,
            email: userinfo.username,
            role: userinfo.role,
            name: userinfo.name,
            username: userinfo.username,
            avatar: "/assets/images/face-6.jpg"
          };

          dispatch({
            type: "INIT",
            payload: { isAuthenticated: true, user }
          });

          const protectedRoute = ['/session/signin', '/session/signup'];
          if (protectedRoute.includes(window.location.pathname)) {
            navigate('/');
          }

        } else {
          dispatch({
            type: "INIT",
            payload: { isAuthenticated: false, user: null }
          });
        }
      } catch (err) {
        //console.error('Initialization error:', err);
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null }
        });
      }
    })();
  }, []);

  if (!state.isInitialized) return <Loading />;

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
