import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";
import Loadable from "app/components/Loadable";

const Defaultoverview = Loadable(lazy(() => import("./default-overview")));

const dashboardRoutes = [
  { path: "/dashboard/default", element: <Defaultoverview />, auth: authRoles.admin }
];

export default dashboardRoutes;
