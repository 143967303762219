import { lazy } from "react";
import Loadable from "app/components/Loadable";
import MissionLogin from "./login/MissionLogin";

const NotFound = Loadable(lazy(() => import("./NotFound")));
const ForgotPassword = Loadable(lazy(() => import("./ForgotPassword")));

const FirebaseLogin = Loadable(lazy(() => import("./login/FirebaseLogin")));
const FirebaseRegister = Loadable(lazy(() => import("./register/FirebaseRegister")));

const JwtLogin = Loadable(lazy(() => import("./login/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("./register/JwtRegister")));
const ResetMessage = Loadable(lazy(() => import("./ResetMessage")));
const ResetPassword = Loadable(lazy(() => import("./ResetPassword")));

// const Auth0Login = Loadable(lazy(() => import("./login/Auth0Login")));

const sessionRoutes = [
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/signin", element: <MissionLogin /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/message", element: <ResetMessage /> },
  { path: "/session/reset-password/:token", element: <ResetPassword /> },
];

export default sessionRoutes;
